/* eslint-disable max-lines */
// import type { MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd/es/menu';
import { Dropdown, Layout, Menu, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { useCustomAppSelector } from '../../components/Auth/useCustomeAppSelector';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import './CBOSidebarStyles.less';
import { RBACMapping } from '../../components/Auth/AuthenticationPageConstants';
import { isAuthorized } from '../../libs/helper/HelperFunctions';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import KeyAssets from '../KeyAssets/KeyAssets';
import { CboViewSummaryActions } from '../../libs/store/actions/cbo/CboViewSummaryAction';
import {
  BottomMenuItemsArray,
  IMenuItem,
  TopMenuItemsArray,
  assetToolTip,
  AssetMenuItems,
  SettingsMenuItems,
  MenuItems,
} from './CBOSidebarConstants';

const { Sider } = Layout;

const CBOSidebar = () => {
  const [showKeyAsset, setShowKeyAsset] = useState<boolean>(false);
  const [assetTitle, setAssetTitle] = useState<string>('');
  const [assetName, setAssetName] = useState<string>('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const rbacData = useCustomAppSelector();
  const { activeKey, selectedEnagagementId, KeyAssetList, isEngagement, selectedEnagagementName } =
    useAppSelector(
      ({
        sidebar: { activeKey },
        cboViewSummary: { selectedEnagagementId, isEngagement, selectedEnagagementName },
        engagement: { KeyAssetList },
      }: IApplicationState) => ({
        activeKey,
        selectedEnagagementId,
        KeyAssetList,
        isEngagement,
        selectedEnagagementName,
      }),
    );

  const dispatcher = {
    setActiveKey: (data: string) => dispatch(SidebarActions.setSidebarKey(data)),
    setSelectedFeature: (data: string) => dispatch(CboViewSummaryActions.setSelectedFeature(data)),
    setSelectedAsset: (data: string) => dispatch(CboViewSummaryActions.setSelectedAsset(data)),
  };

  useEffect(() => {
    dispatcher.setActiveKey(activeKey);
  }, []);

  const routeKeyLookup = {
    [routes.cboUserManagement]: MenuItems.settings.key,
    [routes.cboUserClientMapping]: MenuItems.settings.key,
    [routes.cboReports]: routes.cboReports,
    [routes.clientEngagementManagement]: MenuItems.settings.key,
    [routes.cboCapabilityManagement]: MenuItems.settings.key,
    [routes.cboJourneyBuilder]: MenuItems.settings.key,
    [routes.cboSmartStart]: MenuItems.settings.key,
    [routes.cboDashboard]:
      activeKey === MenuItems.help.key ? MenuItems.help.key : routes.cboDashboard,
    [routes.cboEngagementDashboard]: routes.cboEngagementDashboard,
    [routes.cboMarketplace]: isEngagement ? routes.cboEngagementDashboard : routes.cboDashboard,
    [routes.cboEngagementSummary]: isEngagement
      ? routes.cboEngagementDashboard
      : routes.cboDashboard,
    [routes.cboMarketplaceSearch]: routes.cboMarketplaceSearch,
    [routes.cboFeedback]: routes.cboFeedback,
  };

  useEffect(() => {
    const route = location.pathname;
    const activeKey = routeKeyLookup[route] || route;
    dispatcher.setActiveKey(activeKey);
  }, [location]);

  const handleKeyAssetsEnable = (keyAsset: string) => {
    if (selectedEnagagementId !== '') {
      if (isEngagement) {
        return (
          !KeyAssetList?.some(
            (asset) => asset?.product?.toLowerCase() === keyAsset?.toLowerCase(),
          ) ||
          !KeyAssetList?.find((asset) => asset?.product?.toLowerCase() === keyAsset?.toLowerCase())
            ?.configDone
        );
      } else {
        return !KeyAssetList?.some(
          (asset) => asset?.product?.toLowerCase() === keyAsset?.toLowerCase(),
        );
      }
    } else {
      return true;
    }
  };

  const handleAssetClick = (assetMenu: string, assetTitle: string, assetName: string) => {
    dispatcher.setActiveKey(assetMenu);
    dispatcher.setSelectedFeature('');
    dispatcher.setSelectedAsset(assetName);
    setShowKeyAsset(true);
    setAssetTitle(assetTitle);
    setAssetName(assetName);
  };

  const renderAssetsSubMenuItem = (
    key: number,
    label: string,
    handleAssetClick: (type: string, displayName: string, assetLabel: string) => void,
    handleKeyAssetsEnable: (label: string) => boolean,
    assetToolTip: string,
    displayName: string,
    assetCategory: string,
  ) => (
    <Menu.Item
      key={key}
      disabled={handleKeyAssetsEnable(label)}
      onClick={() => {
        handleAssetClick(assetCategory, displayName, label);
      }}
    >
      {handleKeyAssetsEnable(label) ? (
        <Tooltip placement="right" title={assetToolTip}>
          {displayName}
        </Tooltip>
      ) : (
        <> {displayName} </>
      )}
    </Menu.Item>
  );

  const modernizeAssetsMenu = (
    <Menu>
      {AssetMenuItems?.modernizeAssets?.map((subMenu, index) =>
        renderAssetsSubMenuItem(
          index,
          subMenu.label,
          handleAssetClick,
          handleKeyAssetsEnable,
          assetToolTip,
          subMenu.displayName,
          MenuItems.modernizeAssets.key,
        ),
      )}
    </Menu>
  );

  const operateAssetsMenu = (
    <Menu>
      {AssetMenuItems?.operateAssets?.map((subMenu, index) =>
        renderAssetsSubMenuItem(
          index,
          subMenu.label,
          handleAssetClick,
          handleKeyAssetsEnable,
          assetToolTip,
          subMenu.displayName,
          MenuItems.operateAssets.key,
        ),
      )}
    </Menu>
  );

  const assessAssetsMenu = (
    <Menu>
      {AssetMenuItems?.assessAssets?.map((subMenu, index) =>
        renderAssetsSubMenuItem(
          index,
          subMenu.label,
          handleAssetClick,
          handleKeyAssetsEnable,
          assetToolTip,
          subMenu.displayName,
          MenuItems.assessAssets.key,
        ),
      )}
    </Menu>
  );

  const testAssetsMenu = (
    <Menu>
      {AssetMenuItems?.testAssets?.map((subMenu, index) =>
        renderAssetsSubMenuItem(
          index,
          subMenu.label,
          handleAssetClick,
          handleKeyAssetsEnable,
          assetToolTip,
          subMenu.displayName,
          MenuItems.testAssets.key,
        ),
      )}
    </Menu>
  );

  const settingsMenu = (
    <Menu>
      {SettingsMenuItems.map((menuItem, index) => {
        const { rbacMapping, route, label } = menuItem;
        if (isAuthorized(rbacMapping, rbacData)) {
          return (
            <Menu.Item
              key={index + 1}
              onClick={() => {
                dispatcher.setActiveKey(MenuItems.settings.key);
                navigate(route);
              }}
            >
              {label}
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  );

  const onMenuSelect: MenuProps['onClick'] = (e) => {
    const routeActions = {
      [routes.cboDashboard]: () => navigate(routes.cboDashboard),
      [routes.cboEngagementDashboard]: () => navigate(routes.cboEngagementDashboard),
      [routes.cboMarketplaceSearch]: () => navigate(routes.cboMarketplaceSearch),
      [routes.cboReports]: () => navigate(routes.cboReports),
      help: () => {
        navigate(routes.cboDashboard);
        storage.setItem(STORAGE_CONSTANTS.first_login_response, 'true');
      },
      [routes.cboFeedback]: () => navigate(routes.cboFeedback),
    };
    const selectedAction = routeActions[e.key as string];
    if (selectedAction) {
      if (e.key !== routes.cboMarketplace) {
        storage.removeSessionItem(STORAGE_CONSTANTS.depAuthenticated);
      }
      selectedAction();
      dispatcher.setActiveKey(e.key);
    } else {
      dispatcher.setActiveKey(activeKey);
    }
  };

  const menuIcon = (menuItem: IMenuItem) => {
    return (
      <img
        className="cbo-menu-icon"
        src={`${process.env.PUBLIC_URL}/config-icons/cbo/${menuItem.icon}`}
        alt={menuItem.title}
      />
    );
  };

  const generateDropdownMenu = (
    menuItem: IMenuItem,
    overlay: JSX.Element,
    overlayClassName: string,
  ) => {
    return (
      <Dropdown
        key={menuItem.key}
        overlay={overlay}
        placement="topLeft"
        overlayClassName={overlayClassName}
      >
        <Menu.Item
          key={menuItem.key}
          className={`tour-${menuItem.key} ${
            activeKey !== menuItem.key ? 'inactive-settings' : 'active-settings'
          }`}
          icon={menuIcon(menuItem)}
        >
          {menuItem.title}
        </Menu.Item>
      </Dropdown>
    );
  };

  const renderMenuItems = (menuItems: IMenuItem[]) => {
    return menuItems?.map((menuItem, id) => {
      const menuIcon = (
        <img
          className="cbo-menu-icon"
          src={`${process.env.PUBLIC_URL}/config-icons/cbo/${menuItem.icon}`}
          alt={menuItem.title}
        />
      );
      const menuTitle = menuItem.title.toString();

      if (menuTitle === MenuItems.webanalytcs.title) {
        const isAuthorizedWebAnalytics =
          isAuthorized(RBACMapping.reports, rbacData) ||
          isAuthorized(RBACMapping.pursuitsEngagementsReport, rbacData);
        if (isAuthorizedWebAnalytics) {
          return (
            <Menu.Item key={menuItem.key} className={`tour-${menuItem.key}`} icon={menuIcon}>
              {menuItem.title}
            </Menu.Item>
          );
        }
      } else if (menuTitle === MenuItems.settings.title) {
        const isAuthorizedSettings =
          isAuthorized(RBACMapping.clientEngagementView, rbacData) ||
          isAuthorized(RBACMapping.viewUser, rbacData) ||
          isAuthorized(RBACMapping.journeyList, rbacData) ||
          isAuthorized(RBACMapping.capabilityList, rbacData) ||
          isAuthorized(RBACMapping.viewUserClientMapping, rbacData) ||
          isAuthorized(RBACMapping.smartStart, rbacData);

        if (isAuthorizedSettings) {
          return generateDropdownMenu(menuItem, settingsMenu, 'settings-menu');
        }
      } else if (menuTitle === MenuItems.assessAssets.title) {
        return generateDropdownMenu(menuItem, assessAssetsMenu, 'migration-assets-menu');
      } else if (menuTitle === MenuItems.testAssets.title) {
        return generateDropdownMenu(menuItem, testAssetsMenu, 'qe-assets-menu');
      } else if (menuTitle === MenuItems.modernizeAssets.title) {
        return generateDropdownMenu(menuItem, modernizeAssetsMenu, 'cloud-assets-menu');
      } else if (menuTitle === MenuItems.operateAssets.title) {
        return generateDropdownMenu(menuItem, operateAssetsMenu, 'operate-menu');
      } else {
        return (
          <Menu.Item key={menuItem.key} className={`tour-${menuItem.key}`} icon={menuIcon}>
            {menuItem.title}
          </Menu.Item>
        );
      }

      return null;
    });
  };

  const closeKeyAssetDrawer = () => {
    setShowKeyAsset(false);
    dispatcher.setSelectedFeature('');
    const route = location.pathname;
    dispatcher.setActiveKey(route ?? routes.cboDashboard);
  };

  return (
    <>
      <Sider
        breakpoint="sm"
        collapsible
        trigger={null}
        className="cbo-sidebar-container"
        width={125}
      >
        <div className="cbo-logo">
          <img
            src={`${process.env.PUBLIC_URL}/config-icons/cbo/cbo-full-logo-new.png`}
            alt="cbo logo"
            onClick={() => {
              navigate(routes.cboDashboard);
            }}
          />
        </div>
        <div className="cbo-sidebar-menu-container">
          <div className="cbo-sidebar-menu">
            <div className="cbo-sidebar-top-menu">
              <Menu
                selectedKeys={[activeKey]}
                onClick={onMenuSelect}
                defaultSelectedKeys={['cboDashboard']}
              >
                {renderMenuItems(TopMenuItemsArray)}
              </Menu>
            </div>
          </div>

          <div className="cbo-sidebar-bottom-menu">
            <div style={{ 'height': '1rem', padding: '1rem' }} className="divider-line">
              <hr style={{ width: '85px', borderTop: '1px solid white' }}></hr>
            </div>
            <Menu selectedKeys={[activeKey]} onClick={onMenuSelect}>
              {renderMenuItems(BottomMenuItemsArray)}
            </Menu>
          </div>
        </div>
      </Sider>
      {showKeyAsset && assetTitle && (
        <KeyAssets
          visible={showKeyAsset}
          title={assetTitle}
          assetName={assetName}
          onClose={closeKeyAssetDrawer}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
          selectedAssetConfig={KeyAssetList?.find(
            (asset) => asset.product?.toLowerCase() == assetName.toLowerCase(),
          )}
        />
      )}
    </>
  );
};

export default CBOSidebar;
