/**
 * This file contains the Tooltip component used in the product tour feature.
 * The Tooltip component renders a tooltip with navigation buttons and content for each step of the tour.
 * It also provides functionality to skip the tour, go back to previous steps, and end the tour.
 *
 * Example usage:
 * ```typescriptreact
 * import Tooltip from './Tooltip';
 *
 * const App = () => {
 *   return (
 *     <div>
 *       <Tooltip
 *         continuous={true}
 *         index={0}
 *         step={...}
 *         size={5}
 *         backProps={...}
 *         skipProps={...}
 *         primaryProps={...}
 *         tooltipProps={...}
 *         isLastStep={false}
 *       />
 *     </div>
 *   );
 * };
 * ```
 */

import React from 'react';
import {
  ArrowRightOutlined,
  CloseOutlined,
  LeftCircleFilled,
  LeftCircleOutlined,
  RightCircleFilled,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { TooltipRenderProps } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { routes } from '../../navigation/Routes';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import './TooltipStyles.less';

/**
 * The Tooltip component renders a tooltip with navigation buttons and content for each step of the tour.
 *
 * @param continuous - Whether the tour is continuous or not.
 * @param index - The index of the current step.
 * @param step - The current step object.
 * @param size - The total number of steps in the tour.
 * @param backProps - Props for the "Go Back" button.
 * @param skipProps - Props for the "Skip Tour" button.
 * @param primaryProps - Props for the primary button (either "Next" or "End Tour").
 * @param tooltipProps - Props for the tooltip container.
 * @param isLastStep - Whether the current step is the last step of the tour.
 * @returns The rendered Tooltip component.
 */
const Tooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}: TooltipRenderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dispatcher = {
    setActiveKey: (data: string) => dispatch(SidebarActions.setSidebarKey(data)),
  };

  /**
   * Sets the active key in the sidebar.
   *
   * @param data - The key to set as active.
   */
  const setActiveKey = (data: string) => dispatcher.setActiveKey(data);

  return (
    <div {...tooltipProps} className="tooltip-container">
      <div className="tooltip-header">
        <span className="tooltip-index">
          {index + 1}/{size}
        </span>
        <Typography className="tooltip-title">{step.title && <div>{step.title}</div>}</Typography>
        <span
          {...skipProps}
          onClick={(e) => {
            skipProps.onClick(e);
            setActiveKey(routes.cboDashboard);
            storage.setItem(STORAGE_CONSTANTS.first_login_response, 'false');
          }}
          className="tooltip-close-button"
        >
          <CloseOutlined style={{ fontWeight: 'bold' }} />
        </span>
      </div>
      <Typography className="tooltip-content">{step.content}</Typography>
      <div className="tooltip-footer">
        <div className="tooltip-footer-left-part">
          <Button
            className="skip-tour-button"
            type="link"
            {...skipProps}
            onClick={(e) => {
              skipProps.onClick(e);
              setActiveKey(routes.cboDashboard);
              storage.setItem(STORAGE_CONSTANTS.first_login_response, 'false');
            }}
          >
            Skip Tour <ArrowRightOutlined />
          </Button>
        </div>
        <div className="tooltip-footer-right-part">
          {index > 0 ? (
            isLastStep ? (
              <Button type="link" {...backProps} className="tooltip-button">
                Go Back
              </Button>
            ) : (
              <span>
                <Button type="link" {...backProps} className="tooltip-button">
                  <LeftCircleFilled />
                </Button>
              </span>
            )
          ) : (
            <span className="tooltip-button-disabled">
              <LeftCircleOutlined />
            </span>
          )}
          {continuous &&
            (isLastStep ? (
              <Button
                {...primaryProps}
                onClick={(e) => {
                  primaryProps.onClick(e);
                  if (isLastStep) setActiveKey(routes.cboDashboard);
                  storage.setItem(STORAGE_CONSTANTS.first_login_response, 'false');
                }}
                type="primary"
                className="end-tour-button"
              >
                End Tour
              </Button>
            ) : (
              <span>
                <Button
                  type="link"
                  {...primaryProps}
                  onClick={(e) => {
                    primaryProps.onClick(e);
                    if (isLastStep) setActiveKey(routes.cboDashboard);
                  }}
                  className="tooltip-button"
                >
                  <RightCircleFilled />
                </Button>
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
