/* eslint-disable max-lines */
import { Reducer } from 'redux';
import {
  IJiraInitiativesResponse,
  ILiftAndShiftObject,
  IJiraEpicsResponse,
  IJiraTasksResponse,
  IJiraTaskListResponse,
  IJiraIssueResponse,
  ICarbonCreditsResponse,
  ICarbonFootPrintResponse,
  IEnergySavingsResponse,
  ILegacyGraphData,
  ILegacyDbData,
  ISummaryMetricsResp,
  IArtifactsLOCResponse,
  IArtifactsResponse,
  IDecompositionResponse,
  ISQLGraphDataResponse,
  IExecutionMetricDetailsResponse,
  IWorkflowListResponse,
  IWorkflowReportResponse,
  IDEPAlertScanResponse,
  ICodeQuality,
  IIncidentChartsType,
  IIncidentDetails,
  ICodespacesResponse,
  IProblemGroupOverviewResponse,
  IProblemInsightsRunDetailsData,
  IProblemInsightsAssignmentGroupData,
  IKMSResponse,
  IActiveSummaryResponse,
  IIssueSummaryResponse,
  IRiskSummaryResponse,
  ITransitionTableResponseData,
  IAppsProgressionResponse,
  IServersProgressionResponse,
  IAppsProgressionMonthlyResponse,
  IServersProgressionMonthlyResponse,
  IMigrationPayload,
  IMigrationArcheTypeListResponse,
  ITCOInsightsResponse,
  ICostTrendResponse,
  ICostAllocationSummaryResponse,
  IBlockersAndCausesResponse,
  IAllocationTrendResponse,
  ICloudCostSummaryCostTrendResponse,
  IServersByUpgradeResponse,
  IDispositionsByWaveData,
  IDefectAgeingResponse,
  INumberOfTestCasesResponse,
  ISeverityVsCreatedOnResponse,
  ITableCountResponse,
  ITestRunStatusResponse,
  ITaxonomyEditorData,
  ISpendOptimizationResponse,
  IMultiCloudConsolidationResponse,
  IQuarterCostSummaryResponse,
  ICostByResourceResponse,
  IYTDCostByResourceResponse,
} from '../../../models/cbo/EngagementSummaryGraphModel';
import {
  EngagementSummaryGraphAction,
  EngagementSummaryGraphActionTypes,
} from '../../actions/cbo/EngagementSummaryGraphAction';

import IEngagementSummaryGraphState from '../../state/cbo/EngagementSummaryGraphState';
import {
  IApplicationDipositioning,
  IWavePlan,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import {
  IDiscoveryMetrics,
  ISurveyMetrics,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import { jiraSprintActivities } from '../../../../components/CBO/CBOConstants';
import {
  ISummaryPractitionerDataResp,
  IDashboardCourseStatusResponse,
} from '../../../../containers/CBOEngagementSummary/operate/LearningCenter/LearningCenterConstants';
import { featureNames } from '../../../../containers/CBOEngagementSummary/CBOEngagementSummaryConstants';

const defaultLSData: ILiftAndShiftObject = {
  results: [],
};

const defaultJiraEpicsData: IJiraEpicsResponse = {
  issues: [],
  total: 0,
  startAt: 0,
  maxResults: 8,
};

const defaultJiraTasksData: IJiraTasksResponse = {
  issues: [],
  total: 0,
  startAt: 0,
  maxResults: 8,
};

const defaultJiraTableInitiative: IJiraIssueResponse = {
  issues: [],
  total: 0,
};
const defaultJiraTableEpics: IJiraIssueResponse = {
  issues: [],
  total: 0,
};
const defaultJiraTableTasks: IJiraIssueResponse = {
  issues: [],
  total: 0,
};

const defaultCurrentSprintTask: IJiraIssueResponse = {
  issues: [],
  total: 0,
};

const defaultJiraTableStories: IJiraIssueResponse = {
  issues: [],
  total: 0,
};
export const initialState: IEngagementSummaryGraphState = {
  liftAndShiftChart: defaultLSData,
  jiraInitiativeList: [],
  jiraEpics: defaultJiraEpicsData,
  jiraTasks: defaultJiraTasksData,
  AllSqlData: [],
  DmlSqlData: [],
  OtherSqlData: [],
  DecmpositionData: [],
  loading: false,
  error: '',
  CodeQualityLow: [],
  CodeQualityMedium: [],
  CodeQualityHigh: [],
  CodeQualityVeryHigh: [],
  AnalyzedArtifactsData: [],
  MissingArtifactsData: [],
  NumberofLOC: [],
  MetricsModule: [],
  PhysicalFiles: [],
  PhysicalLOC: [],
  SourceLOC: [],
  CommentLOC: [],
  DeadCode: [],
  MissingModules: [],
  Errors: [],
  jiraIssuesInitiative: defaultJiraTableInitiative,
  jiraInitiativeLoading: false,
  jiraIssuesInitiativeModernize: defaultJiraTableInitiative, //Modernize CSA
  jiraInitiativeModernizeLoading: false, //Modernize CSA
  jiraTableEpics: defaultJiraTableEpics,
  jiraEpicsLoading: false,
  jiraTableEpicsModernize: defaultJiraTableEpics, //modernize
  jiraEpicsModernizeLoading: false, //modernize
  jiraTableTasks: defaultJiraTableTasks,
  jiraTableTaskLoading: false,
  jiraTableTasksModernize: defaultJiraTableTasks, //modernize
  jiraTableTaskModernizeLoading: false, //modernize
  jiraCurrentSprintTask: defaultCurrentSprintTask,
  jiraCurrentSprintTaskLoading: false,
  jiraModernizeSprintActivities: defaultCurrentSprintTask, //modernize
  jiraModernizeSprintActivitiesLoading: false, //migrate
  jiraTableStoriesMigrate: defaultJiraTableStories, //migrate
  jiraTableStoriesLoading: false, //migrate
  csaTaskLoading: false, // Cloud Migration Fecthing Tasks based on epic Id
  csaTaskList: {} as IJiraTaskListResponse, // Cloud Migration Fecthing Tasks based on epic Id
  CodeQualityLowForPCC: [],
  CodeQualityMediumForPCC: [],
  CodeQualityHighForPCC: [],
  CodeQualityVeryHighForPCC: [],
  CodeQualityLowForLCC: [],
  CodeQualityMediumForLCC: [],
  CodeQualityHighForLCC: [],
  CodeQualityVeryHighForLCC: [],
  CodeQualityLowSC: [],
  ApplicationDespositioning: {} as IApplicationDipositioning,
  wavePlan: {} as IWavePlan,
  incidentSummaryTrends: {} as IIncidentChartsType,

  inventorySurvey: {} as ISurveyMetrics,
  businessSummary: {} as ISurveyMetrics,
  discoveryMetrics: {} as IDiscoveryMetrics,

  incidentDetails: {} as IIncidentDetails,
  legacyGraphData: {} as ILegacyGraphData,

  AllSqlDataCompleted: false,
  DmlSqlDataCompleted: false,
  OtherSqlDataCompleted: false,
  DecmpositionDataCompleted: false,
  MissingArtifactsDataCompleted: false,
  AnalyzedArtifactsDataCompleted: false,
  NumberofLOCCompleted: false,
  CodeQualityLowCompleted: false,
  CodeQualityMediumCompleted: false,
  CodeQualityHighCompleted: false,
  CodeQualityVeryHighCompleted: false,
  CodeQualityLowForPCCCompleted: false,
  CodeQualityMediumForPCCCompleted: false,
  CodeQualityHighForPCCCompleted: false,
  CodeQualityVeryHighForPCCCompleted: false,
  CodeQualityLowForLCCCompleted: false,
  CodeQualityMediumForLCCCompleted: false,
  CodeQualityHighForLCCCompleted: false,
  CodeQualityVeryHighForLCCCompleted: false,
  CodeQualityLowSCCompleted: false,
  MetricsModuleCompleted: false,
  PhysicalFilesCompleted: false,
  PhysicalLOCCompleted: false,
  SourceLOCCompleted: false,
  CommentLOCCompleted: false,
  DeadCodeCompleted: false,
  MissingModulesCompleted: false,
  ErrorsCompleted: false,
  discoveryMetricsCallCompleted: false,
  inventorySurveyMetricsCallCompleted: false,
  businessSurveyMetricsCallCompleted: false,
  ApplicationDepositioningCallCompleted: false,
  wavePlanCallCompleted: false,
  incidentSummaryCallCompleted: false,
  incidentDetailsCallCompleted: false,

  setKeyAssetConfigLoading: false,

  carbonFootPrintResponse: {} as ICarbonFootPrintResponse,
  carbonFootPrintCompleted: false,
  carbonCreditsResponse: {} as ICarbonCreditsResponse,
  carbonCreditsCompleted: false,
  energySavingsResponse: {} as IEnergySavingsResponse,
  energySavingsCompleted: false,

  //Learning center
  summaryPractitionerDataResponse: {} as ISummaryPractitionerDataResp,
  summaryPractitionerDataResponseCompleted: false,
  coursesStatusDataResponse: [],
  coursesStatusDataResponseCompleted: false,

  legacyDbData: {} as ILegacyDbData[],
  legacyDbCompleted: false,
  legacyGraphCompleted: false,

  workflowsListData: [],
  workflowListDataCompleted: false,
  workflowExecutionMetricsDetails: {} as IExecutionMetricDetailsResponse,
  workflowExecutionMetricsDetailsCompleted: false,
  workflowReportsData: {} as IWorkflowReportResponse,
  workflowReportsDataCompleted: false,

  depAlertsScanData: {} as IDEPAlertScanResponse,
  depAlertsScanCompleted: false,
  depCodespacesData: [],
  depCodespacesCompleted: false,
  problemGroupOverview: {} as IProblemGroupOverviewResponse,
  problemGroupOverviewCompleted: false,
  problemInsightsRunDetails: {} as IProblemInsightsRunDetailsData,
  problemInsightsRunDetailsCompleted: false,
  problemInsightsRunListAssignmentGroup: [] as IProblemInsightsAssignmentGroupData[],
  problemInsightsRunListAssignmentGroupCompleted: false,
  kmsResponseData: [] as IKMSResponse[],
  kmsResponseDataCompleted: false,
  riskSummaryResponseData: [] as IRiskSummaryResponse[],
  issueSummaryResponseData: [] as IIssueSummaryResponse[],
  activeSummaryResponseData: {} as IActiveSummaryResponse,
  transitionsResponseData: [] as ITransitionTableResponseData[],
  riskSummaryDataCompleted: false,
  issueSummaryDataCompleted: false,
  activesummaryDataCompleted: false,
  transitionsDataCompleted: false,
  archetypesListData: {} as IMigrationArcheTypeListResponse,
  archetypesListCompleted: false,
  appsProgressionResponse: [],
  appsProgressionCompleted: false,
  serversProgressionCompleted: false,
  serversProgressionResponse: [],
  appsProgressionMonthlyResponse: [],
  appsProgressionMonthlyCompleted: false,
  serversProgressionMonthlyResponse: [],
  serversProgressionMonthlyCompleted: false,
  mdotArchetypesListCompleted: false,
  blockersandCausesListCompleted: false,
  tcoInsightsResponseData: {} as ITCOInsightsResponse,
  tcoInsightsCompleted: false,
  costTrendsResponseData: [] as ICostTrendResponse[],
  costTrendsCompleted: false,
  costAllocationSummaryResponseData: {} as ICostAllocationSummaryResponse,
  costAllocationSummaryCompleted: false,
  blockersandCausesResponseData: {} as IBlockersAndCausesResponse,
  blockersandCausesResponseCompleted: true,
  AllocationTrendData: {} as IAllocationTrendResponse,
  AllocationTrendCompleted: false,
  cloudCostSummaryCostTrendData: [] as ICloudCostSummaryCostTrendResponse[],
  cloudCostSummaryCostTrendCompleted: false,
  serversByUpgradeResponseData: [] as IServersByUpgradeResponse[],
  serversByUpgradeResponseDataCompleted: false,
  dispositionsByWaveResponseData: {} as IDispositionsByWaveData,
  dispositionsByWaveDataCompleted: true,
  //CBO-4166_AI_AUTO_ENHANCE_CODE
  defectAgeingResponseData: {} as IDefectAgeingResponse,
  defectAgeingResponseDataCompleted: true,
  numberOfTestCasesResponseData: {} as INumberOfTestCasesResponse,
  numberOfTestCasesResponseDataCompleted: true,
  severityVsCreatedOnResponseData: {} as ISeverityVsCreatedOnResponse,
  severityVsCreatedOnResponseDataCompleted: true,
  tableCountResponseData: {} as ITableCountResponse,
  tableCountResponseDataCompleted: true,
  testRunStatusResponseData: {} as ITestRunStatusResponse,
  testRunStatusResponseDataCompleted: true,

  taxonomyEditorResponseData: [],
  taxonomyEditorDataCompleted: false,
  spendOptimizationResponse: {} as ISpendOptimizationResponse,
  spendOptimizationCompleted: false,
  multicloudConsolidationData: [],
  multicloudConsolidationCompleted: false,
  qaurterCostSummaryData: {} as IQuarterCostSummaryResponse,
  quarterCostSummaryCompleted: false,
  costByResourceData: [],
  costByResourceCompleted: false,
  ytdCostByResourceGroupData: [],
  ytdCostByResourceGroupCompleted: false,
};

export const engagementSummaryGraphReducer: Reducer<IEngagementSummaryGraphState> = (
  state = initialState,
  action: EngagementSummaryGraphAction,
) => {
  switch (action.type) {
    case EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_STARTED:
      return {
        ...state,
        loading: true,
        liftAndShiftChart: defaultLSData,
      };
    case EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        liftAndShiftChart: action.data as ILiftAndShiftObject,
      };
    case EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_STARTED:
      return {
        ...state,
        loading: true,
        jiraInitiativeList: [],
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        jiraInitiativeList: action.data as IJiraInitiativesResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_STARTED:
      return {
        ...state,
        loading: true,
        jiraEpics: defaultJiraEpicsData,
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_SUCCESS:
      return {
        ...state,
        loading: false,
        jiraEpics: action.data as IJiraEpicsResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_JIRA_TASKS_STARTED:
      return {
        ...state,
        loading: true,
        jiraTasks: defaultJiraTasksData,
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        jiraTasks: action.data as IJiraTasksResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_TASKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_START:
      return {
        ...state,
        AllSqlDataCompleted: false,
        AllSqlData: [],
      };
    case EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        AllSqlDataCompleted: true,
        AllSqlData: action.data as ISQLGraphDataResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_ERROR:
      return {
        ...state,
        AllSqlDataCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_START:
      return {
        ...state,
        DmlSqlDataCompleted: false,
        DmlSqlData: [],
      };
    case EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        DmlSqlDataCompleted: true,
        DmlSqlData: action.data as ISQLGraphDataResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_ERROR:
      return {
        ...state,
        DmlSqlDataCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_START:
      return {
        ...state,
        OtherSqlDataCompleted: false,
        OtherSqlData: [],
      };
    case EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_SUCCESS:
      return {
        ...state,

        OtherSqlDataCompleted: true,
        OtherSqlData: action.data as ISQLGraphDataResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_ERROR:
      return {
        ...state,

        OtherSqlDataCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_START:
      return {
        ...state,

        DecmpositionDataCompleted: false,
        DecmpositionData: [],
      };
    case EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_SUCCESS:
      return {
        ...state,

        DecmpositionDataCompleted: true,
        DecmpositionData: action.data as IDecompositionResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_ERROR:
      return {
        ...state,

        DecmpositionDataCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_START:
      return {
        ...state,

        MissingArtifactsDataCompleted: false,
        MissingArtifactsData: [],
      };
    case EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_SUCCESS:
      return {
        ...state,

        MissingArtifactsDataCompleted: true,
        MissingArtifactsData: action.data as IArtifactsResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_ERROR:
      return {
        ...state,

        MissingArtifactsDataCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_START:
      return {
        ...state,

        AnalyzedArtifactsDataCompleted: false,
        AnalyzedArtifactsData: [],
      };
    case EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_SUCCESS:
      return {
        ...state,

        AnalyzedArtifactsDataCompleted: true,
        AnalyzedArtifactsData: action.data as IArtifactsResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_ERROR:
      return {
        ...state,

        AnalyzedArtifactsDataCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_START:
      return {
        ...state,

        NumberofLOCCompleted: false,
        NumberofLOC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_SUCCESS:
      return {
        ...state,

        NumberofLOCCompleted: true,
        NumberofLOC: action.data as IArtifactsLOCResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_ERROR:
      return {
        ...state,

        NumberofLOCCompleted: true,
        error: action.data,
      };
    //code quality

    //low
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_START:
      return {
        ...state,

        CodeQualityLowCompleted: false,
        CodeQualityLow: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_SUCCESS:
      return {
        ...state,

        CodeQualityLowCompleted: true,
        CodeQualityLow: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_ERROR:
      return {
        ...state,

        CodeQualityLowCompleted: true,
        error: action.data,
      };

    //low FCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_START:
      return {
        ...state,

        CodeQualityLowForPCCCompleted: false,
        CodeQualityLowForPCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_SUCCESS:
      return {
        ...state,

        CodeQualityLowForPCCCompleted: true,
        CodeQualityLowForPCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_ERROR:
      return {
        ...state,
        CodeQualityLowForPCCCompleted: true,

        error: action.data,
      };

    //low LCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_START:
      return {
        ...state,

        CodeQualityLowForLCCCompleted: false,
        CodeQualityLowForLCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_SUCCESS:
      return {
        ...state,

        CodeQualityLowForLCCCompleted: true,
        CodeQualityLowForLCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_ERROR:
      return {
        ...state,

        CodeQualityLowForLCCCompleted: true,
        error: action.data,
      };

    //low SC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_START:
      return {
        ...state,

        CodeQualityLowSCCompleted: false,
        CodeQualityLowForSC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_SUCCESS:
      return {
        ...state,

        CodeQualityLowSCCompleted: true,
        CodeQualityLowForSC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_ERROR:
      return {
        ...state,

        CodeQualityLowSCCompleted: true,
        error: action.data,
      };

    //medium
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_START:
      return {
        ...state,

        CodeQualityMediumCompleted: false,
        CodeQualityMedium: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_SUCCESS:
      return {
        ...state,

        CodeQualityMediumCompleted: true,
        CodeQualityMedium: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_ERROR:
      return {
        ...state,

        CodeQualityMediumCompleted: true,
        error: action.data,
      };

    //medium FCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_START:
      return {
        ...state,

        CodeQualityMediumForPCCCompleted: false,
        CodeQualityMediumForPCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_SUCCESS:
      return {
        ...state,

        CodeQualityMediumForPCCCompleted: true,
        CodeQualityMediumForPCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_ERROR:
      return {
        ...state,

        CodeQualityMediumForPCCCompleted: true,
        error: action.data,
      };

    //medium LCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_START:
      return {
        ...state,

        CodeQualityMediumForLCCCompleted: false,
        CodeQualityMediumForLCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_SUCCESS:
      return {
        ...state,

        CodeQualityMediumForLCCCompleted: true,
        CodeQualityMediumForLCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_ERROR:
      return {
        ...state,

        CodeQualityMediumForLCCCompleted: true,
        error: action.data,
      };

    //high
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_START:
      return {
        ...state,

        CodeQualityHighCompleted: false,
        CodeQualityHigh: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_SUCCESS:
      return {
        ...state,

        CodeQualityHighCompleted: true,
        CodeQualityHigh: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_ERROR:
      return {
        ...state,

        CodeQualityHighCompleted: true,
        error: action.data,
      };
    //high FCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_START:
      return {
        ...state,

        CodeQualityHighForPCCCompleted: false,
        CodeQualityHighForPCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS:
      return {
        ...state,

        CodeQualityHighForPCCCompleted: true,
        CodeQualityHighForPCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_ERROR:
      return {
        ...state,

        CodeQualityHighForPCCCompleted: true,
        error: action.data,
      };

    //high LCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_START:
      return {
        ...state,

        CodeQualityHighForLCCCompleted: false,
        CodeQualityHighForLCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS:
      return {
        ...state,
        loading: false,
        CodeQualityHighForLCCCompleted: true,
        CodeQualityHighForLCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_ERROR:
      return {
        ...state,

        CodeQualityHighForLCCCompleted: true,
        error: action.data,
      };

    //very high
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_START:
      return {
        ...state,
        CodeQualityVeryHighCompleted: false,

        CodeQualityVeryHigh: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        CodeQualityVeryHighCompleted: true,

        CodeQualityVeryHigh: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_ERROR:
      return {
        ...state,
        CodeQualityVeryHighCompleted: true,

        error: action.data,
      };

    //very high FCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_START:
      return {
        ...state,

        CodeQualityVeryHighForPCCCompleted: false,
        CodeQualityVeryHighForPCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS:
      return {
        ...state,

        CodeQualityVeryHighForPCCCompleted: true,
        CodeQualityVeryHighForPCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_ERROR:
      return {
        ...state,

        CodeQualityVeryHighForPCCCompleted: true,
        error: action.data,
      };
    //very high LCC
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_START:
      return {
        ...state,

        CodeQualityVeryHighForLCCCompleted: false,
        CodeQualityVeryHighForLCC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS:
      return {
        ...state,

        CodeQualityVeryHighForLCCCompleted: true,
        CodeQualityVeryHighForLCC: action.data as ICodeQuality[],
      };
    case EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_ERROR:
      return {
        ...state,

        CodeQualityVeryHighForLCCCompleted: true,
        error: action.data,
      };

    //SUMMARY MATRICS
    case EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_START:
      return {
        ...state,
        MetricsModuleCompleted: false,
        loading: true,
        MetricsModule: [],
      };
    case EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_SUCCESS:
      return {
        ...state,
        MetricsModuleCompleted: true,
        loading: false,
        MetricsModule: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_ERROR:
      return {
        ...state,
        MetricsModuleCompleted: true,
        loading: false,
        error: action.data,
      };
    //Physical Files
    case EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_START:
      return {
        ...state,
        PhysicalFilesCompleted: false,
        loading: true,
        PhysicalFiles: [],
      };
    case EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_SUCCESS:
      return {
        ...state,
        PhysicalFilesCompleted: true,
        loading: false,
        PhysicalFiles: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_ERROR:
      return {
        ...state,
        PhysicalFilesCompleted: true,
        loading: false,
        error: action.data,
      };

    //Physical LOC
    case EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_START:
      return {
        ...state,
        PhysicalLOCCompleted: false,
        loading: true,
        PhysicalLOC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        PhysicalLOCCompleted: true,
        PhysicalLOC: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_ERROR:
      return {
        ...state,
        PhysicalLOCCompleted: true,
        loading: false,
        error: action.data,
      };
    //Source LOC
    case EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_START:
      return {
        ...state,
        SourceLOCCompleted: false,
        loading: true,
        SourceLOC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_SUCCESS:
      return {
        ...state,
        SourceLOCCompleted: true,
        loading: false,
        SourceLOC: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_ERROR:
      return {
        ...state,
        SourceLOCCompleted: true,
        loading: false,
        error: action.data,
      };

    //Coment LOC
    case EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_START:
      return {
        ...state,
        CommentLOCCompleted: false,
        loading: true,
        CommentLOC: [],
      };
    case EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_SUCCESS:
      return {
        ...state,
        CommentLOCCompleted: true,
        loading: false,
        CommentLOC: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_ERROR:
      return {
        ...state,
        CommentLOCCompleted: true,
        loading: false,
        error: action.data,
      };

    //Dead Code
    case EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_START:
      return {
        ...state,
        DeadCodeCompleted: false,
        loading: true,
        DeadCode: [],
      };
    case EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_SUCCESS:
      return {
        ...state,
        DeadCodeCompleted: true,
        loading: false,
        DeadCode: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_ERROR:
      return {
        ...state,
        DeadCodeCompleted: true,
        loading: false,
        error: action.data,
      };
    //Missing Modules
    case EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_START:
      return {
        ...state,
        loading: true,
        MissingModulesCompleted: false,
        MissingModules: [],
      };
    case EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        MissingModulesCompleted: true,
        MissingModules: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        MissingModulesCompleted: true,
        error: action.data,
      };
    //Errors
    case EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_START:
      return {
        ...state,
        ErrorsCompleted: false,
        loading: true,
        Errors: [],
      };
    case EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_SUCCESS:
      return {
        ...state,
        ErrorsCompleted: true,
        loading: false,
        Errors: action.data as ISummaryMetricsResp[],
      };
    case EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_ERROR:
      return {
        ...state,
        ErrorsCompleted: true,
        loading: false,
        error: action.data,
      };

    //New JIra Table//

    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_STARTED:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraInitiativeLoading: true,
          jiraIssuesInitiative: defaultJiraTableInitiative,
        };
      } else {
        return {
          ...state,
          jiraIssuesInitiativeModernize: defaultJiraTableInitiative, //Modernize CSA
          jiraInitiativeModernizeLoading: true, //Modernize CSA
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_SUCCESS:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraInitiativeLoading: false,
          jiraIssuesInitiative: action.data as IJiraIssueResponse,
        };
      } else {
        return {
          ...state,
          jiraInitiativeModernizeLoading: false,
          jiraIssuesInitiativeModernize: action.data as IJiraIssueResponse,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_ERROR:
      if ((action.data as Error).name === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraInitiativeLoading: false,
          error: action.data,
        };
      } else {
        return {
          ...state,
          jiraInitiativeModernizeLoading: false,
          error: action.data,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_STARTED:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraEpicsLoading: true,
          jiraTableEpics: defaultJiraTableEpics,
        };
      } else {
        return {
          ...state,
          jiraEpicsModernizeLoading: true,
          jiraTableEpicsModernize: defaultJiraTableEpics,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_SUCCESS:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraEpicsLoading: false,
          jiraTableEpics: action.data as IJiraIssueResponse,
        };
      } else {
        return {
          ...state,
          jiraEpicsModernizeLoading: false,
          jiraTableEpicsModernize: action.data as IJiraIssueResponse,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_ERROR:
      if ((action.data as Error)?.name === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraEpicsLoading: false,
          error: action.data,
        };
      } else {
        return {
          ...state,
          jiraEpicsModernizeLoading: false,
          error: action.data,
        };
      }
    // Cloud Migration Fecthing Tasks based on epic Id
    case EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_STARTED:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraCurrentSprintTaskLoading: true,
          jiraCurrentSprintTask: defaultCurrentSprintTask,
        };
      } else {
        return {
          ...state,
          jiraModernizeSprintActivitiesLoading: true,
          jiraModernizeSprintActivities: defaultCurrentSprintTask,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_SUCCESS:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraCurrentSprintTaskLoading: false,
          jiraCurrentSprintTask: action.data as IJiraIssueResponse,
        };
      } else {
        return {
          ...state,
          jiraModernizeSprintActivitiesLoading: false,
          jiraModernizeSprintActivities: action.data as IJiraIssueResponse,
        };
      }

    case EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_ERROR:
      if ((action.data as Error)?.name === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraCurrentSprintTaskLoading: false,
          error: action.data as Error,
        };
      } else {
        return {
          ...state,
          jiraModernizeSprintActivitiesLoading: false,
          error: action.data as Error,
        };
      }

    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_STARTED:
      return {
        ...state,
        jiraTableStoriesMigrate: defaultJiraTableStories, //migrate
        jiraTableStoriesLoading: true, //migrate
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_SUCCESS:
      return {
        ...state,
        jiraTableStoriesLoading: false,
        jiraTableStoriesMigrate: action.data as IJiraIssueResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_ERROR:
      return {
        ...state,
        jiraTableStoriesLoading: false,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_STARTED:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraTableTaskLoading: true,
          jiraTableTasks: defaultJiraTableTasks,
        };
      } else {
        return {
          ...state,
          jiraTableTaskModernizeLoading: true,
          jiraTableTasksModernize: defaultJiraTableTasks,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_SUCCESS:
      if ((action.data as IJiraIssueResponse)?.phase === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraTableTaskLoading: false,
          jiraTableTasks: action.data as IJiraIssueResponse,
        };
      } else {
        return {
          ...state,
          jiraTableTaskModernizeLoading: false,
          jiraTableTasksModernize: action.data as IJiraIssueResponse,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_ERROR:
      if ((action.data as Error)?.name === jiraSprintActivities.migrate) {
        return {
          ...state,
          jiraTableTaskLoading: false,
          error: action.data as Error,
        };
      } else {
        return {
          ...state,
          jiraTableTaskModernizeLoading: false,
          error: action.data as Error,
        };
      }
    //inventory summary
    case EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_START:
      return {
        ...state,
        inventorySurveyMetricsCallCompleted: false,
        inventorySurvey: {} as ISurveyMetrics,
      };
    case EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        inventorySurveyMetricsCallCompleted: true,
        inventorySurvey: action.data as ISurveyMetrics,
      };
    case EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_ERROR:
      return {
        ...state,
        inventorySurveyMetricsCallCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_START:
      return {
        ...state,
        businessSurveyMetricsCallCompleted: false,
        businessSummary: {} as ISurveyMetrics,
      };
    case EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        businessSurveyMetricsCallCompleted: true,
        businessSummary: action.data as ISurveyMetrics,
      };
    case EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_ERROR:
      return {
        ...state,
        businessSurveyMetricsCallCompleted: true,
        error: action.data as Error,
      };

    //discovery metrics
    case EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_START:
      return {
        ...state,
        discoveryMetricsCallCompleted: false,
        discoveryMetrics: {} as IDiscoveryMetrics,
      };
    case EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_SUCCESS:
      return {
        ...state,
        discoveryMetricsCallCompleted: true,
        discoveryMetrics: action.data as IDiscoveryMetrics,
      };
    case EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_ERROR:
      return {
        ...state,
        discoveryMetricsCallCompleted: true,
        error: action.data as Error,
      };

    //Application Depositioning
    case EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_STARTED:
      return {
        ...state,
        ApplicationDepositioningCallCompleted: false,
        ApplicationDespositioning: {} as IApplicationDipositioning,
      };
    case EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_SUCCESS:
      return {
        ...state,
        ApplicationDepositioningCallCompleted: true,
        ApplicationDespositioning: action.data as IApplicationDipositioning,
      };
    case EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_ERROR:
      return {
        ...state,
        ApplicationDepositioningCallCompleted: true,
        error: action.data as Error,
      };

    //Wave plan
    case EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_STARTED:
      return {
        ...state,
        wavePlanCallCompleted: false,
        wavePlan: {} as IWavePlan,
      };
    case EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_SUCCESS:
      return {
        ...state,
        wavePlanCallCompleted: true,
        wavePlan: action.data as IWavePlan,
      };
    case EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_ERROR:
      return {
        ...state,
        wavePlanCallCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_STARTED:
      return {
        ...state,
        incidentSummaryCallCompleted: false,
        incidentSummaryTrends: {} as IIncidentChartsType,
      };
    case EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_SUCCESS:
      return {
        ...state,
        incidentSummaryCallCompleted: true,
        incidentSummaryTrends: action.data as IIncidentChartsType,
      };
    case EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_ERROR:
      return {
        ...state,
        incidentSummaryCallCompleted: true,
        error: action.data as Error,
      };

    // For the '/smartsheet/portfoliobyowner' API

    //INCIDENT DETAILS
    case EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_STARTED:
      return {
        ...state,
        incidentDetailsCallCompleted: false,
        incidentDetails: {} as IIncidentDetails,
      };
    case EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_SUCCESS:
      return {
        ...state,
        incidentDetailsCallCompleted: true,
        incidentDetails: action.data as IIncidentDetails,
      };
    case EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_ERROR:
      return {
        ...state,
        incidentDetailsCallCompleted: true,
        error: action.data,
      };

    //high level status

    case EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_STARTED:
      return {
        ...state,
        loading: true,
        setKeyAssetConfigLoading: true,
      };
    case EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        setKeyAssetConfigLoading: false,
      };
    case EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_ERROR:
      return {
        ...state,
        loading: false,
        setKeyAssetConfigLoading: false,
        error: action.data,
      };
    //Green Cloud
    case EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_START:
      return {
        ...state,
        carbonFootPrintResponse: {} as ICarbonFootPrintResponse,
        carbonFootPrintCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_SUCCESS:
      return {
        ...state,
        carbonFootPrintResponse: action.data as ICarbonFootPrintResponse,
        carbonFootPrintCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_ERROR:
      return {
        ...state,
        carbonFootPrintCompleted: true,
        error: action.data,
      };
    case EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_START:
      return {
        ...state,
        carbonCreditsResponse: {} as ICarbonCreditsResponse,
        carbonCreditsCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_SUCCESS:
      return {
        ...state,
        carbonCreditsResponse: action.data as ICarbonCreditsResponse,
        carbonCreditsCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_ERROR:
      return {
        ...state,
        carbonCreditsCompleted: true,
        error: action.data,
      };
    case EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_START:
      return {
        ...state,
        energySavingsResponse: {} as IEnergySavingsResponse,
        energySavingsCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_SUCCESS:
      return {
        ...state,
        energySavingsResponse: action.data as IEnergySavingsResponse,
        energySavingsCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_ERROR:
      return {
        ...state,
        energySavingsCompleted: true,
        error: action.data,
      };
    case EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_START:
      return {
        ...state,
        summaryPractitionerDataResponse: {} as ISummaryPractitionerDataResp,
        summaryPractitionerDataResponseCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryPractitionerDataResponse: action.data as ISummaryPractitionerDataResp,
        summaryPractitionerDataResponseCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_ERROR:
      return {
        ...state,
        summaryPractitionerDataResponseCompleted: true,
        error: action.data,
      };

    case EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_START:
      return {
        ...state,
        coursesStatusDataResponse: [],
        coursesStatusDataResponseCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_SUCCESS:
      return {
        ...state,
        coursesStatusDataResponse: action.data as IDashboardCourseStatusResponse[],
        coursesStatusDataResponseCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_ERROR:
      return {
        ...state,
        coursesStatusDataResponseCompleted: true,
        error: action.data,
      };
    //Legacy DB Graph
    case EngagementSummaryGraphActionTypes.GET_LEGACY_DB_STARTED:
      return {
        ...state,
        legacyDbCompleted: false,
        legacyDbData: {} as ILegacyDbData[],
      };
    case EngagementSummaryGraphActionTypes.GET_LEGACY_DB_SUCCESS:
      return {
        ...state,
        legacyDbCompleted: true,
        legacyDbData: action.data as ILegacyDbData[],
      };

    case EngagementSummaryGraphActionTypes.GET_LEGACY_DB_ERROR:
      return {
        ...state,
        legacyDbCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_STARTED:
      return {
        ...state,
        legacyGraphCompleted: false,
        legacyGraphData: {} as ILegacyGraphData,
      };
    case EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_SUCCESS:
      return {
        ...state,
        legacyGraphCompleted: true,
        legacyGraphData: action.data as unknown as ILegacyGraphData,
      };
    case EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_ERROR:
      return {
        ...state,
        legacyGraphCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_STARTED:
      return {
        ...state,
        workflowsListData: [],
        workflowListDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        workflowListDataCompleted: true,
        workflowsListData: action.data as IWorkflowListResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_ERROR:
      return {
        ...state,
        workflowListDataCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_STARTED:
      return {
        ...state,
        workflowExecutionMetricsDetails: {} as IExecutionMetricDetailsResponse,
        workflowExecutionMetricsDetailsCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_SUCCESS:
      return {
        ...state,
        workflowExecutionMetricsDetailsCompleted: true,
        workflowExecutionMetricsDetails: action.data as IExecutionMetricDetailsResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_ERROR:
      return {
        ...state,
        workflowExecutionMetricsDetailsCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_STARTED:
      return {
        ...state,
        workflowReportsData: {} as IWorkflowReportResponse,
        workflowReportsDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_SUCCESS:
      return {
        ...state,
        workflowReportsDataCompleted: true,
        workflowReportsData: action.data as IWorkflowReportResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_ERROR:
      return {
        ...state,
        workflowReportsDataCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_STARTED:
      return {
        ...state,
        depAlertsScanCompleted: false,
        depAlertsScanData: {} as IDEPAlertScanResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_SUCCESS:
      return {
        ...state,
        depAlertsScanCompleted: true,
        depAlertsScanData: action.data as IDEPAlertScanResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_ERROR:
      return {
        ...state,
        depAlertsScanCompleted: true,
        depAlertsScanData: {} as IDEPAlertScanResponse,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_STARTED:
      return {
        ...state,
        depCodespacesCompleted: false,
        depCodespacesData: [] as ICodespacesResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_SUCCESS:
      return {
        ...state,
        depCodespacesCompleted: true,
        depCodespacesData: action.data as ICodespacesResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_ERROR:
      return {
        ...state,
        depCodespacesCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_STARTED:
      return {
        ...state,
        problemInsightsRunListAssignmentGroup: [] as IProblemInsightsAssignmentGroupData[],
        problemInsightsRunListAssignmentGroupCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        problemInsightsRunListAssignmentGroupCompleted: true,
        problemInsightsRunListAssignmentGroup: action.data as IProblemInsightsAssignmentGroupData[],
      };
    case EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_ERROR:
      return {
        ...state,
        problemInsightsRunListAssignmentGroupCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_STARTED:
      return {
        ...state,
        problemInsightsRunDetails: {} as IProblemInsightsRunDetailsData,
        problemInsightsRunDetailsCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_SUCCESS:
      return {
        ...state,
        problemInsightsRunDetailsCompleted: true,
        problemInsightsRunDetails: action.data as IProblemInsightsRunDetailsData,
      };
    case EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_ERROR:
      return {
        ...state,
        problemInsightsRunDetailsCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_STARTED:
      return {
        ...state,
        problemGroupOverview: {} as IProblemGroupOverviewResponse,
        problemGroupOverviewCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_SUCCESS:
      return {
        ...state,
        problemGroupOverviewCompleted: true,
        problemGroupOverview: action.data as IProblemGroupOverviewResponse,
      };
    case EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_ERROR:
      return {
        ...state,
        problemGroupOverviewCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_STARTED:
      return {
        ...state,
        kmsResponseData: [] as IKMSResponse[],
        kmsResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_SUCCESS:
      return {
        ...state,
        kmsResponseDataCompleted: true,
        kmsResponseData: action.data as IKMSResponse[],
      };
    case EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_ERROR:
      return {
        ...state,
        kmsResponseDataCompleted: true,
        error: action.data as Error,
      };
    // Risk Summary Data
    case EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_STARTED:
      return {
        ...state,
        riskSummaryResponseData: [] as IRiskSummaryResponse[],
        riskSummaryDataCompleted: false,
      };

    case EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        riskSummaryResponseData: action.data as IRiskSummaryResponse[],
        riskSummaryDataCompleted: true,
      };

    case EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_ERROR:
      return {
        ...state,
        riskSummaryDataCompleted: true,
        error: action.data as Error,
      };

    // Issue Summary Data
    case EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_STARTED:
      return {
        ...state,
        issueSummaryResponseData: [] as IIssueSummaryResponse[],
        issueSummaryDataCompleted: false,
      };

    case EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        issueSummaryResponseData: action.data as IIssueSummaryResponse[],
        issueSummaryDataCompleted: true,
      };

    case EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_ERROR:
      return {
        ...state,
        issueSummaryDataCompleted: true,
        error: action.data as Error,
      };

    // Active Summary Data
    case EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_STARTED:
      return {
        ...state,
        activeSummaryResponseData: {} as IActiveSummaryResponse,
        activesummaryDataCompleted: false,
      };

    case EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        activeSummaryResponseData: action.data as IActiveSummaryResponse,
        activesummaryDataCompleted: true,
      };

    case EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_ERROR:
      return {
        ...state,
        activesummaryDataCompleted: true,
        error: action.data as Error,
      };

    // Transitions Data
    case EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_STARTED:
      return {
        ...state,
        transitionsResponseData: [] as ITransitionTableResponseData[],
        transitionsDataCompleted: false,
      };

    case EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_SUCCESS:
      return {
        ...state,
        transitionsResponseData: action.data as ITransitionTableResponseData[],
        transitionsDataCompleted: true,
      };

    case EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_ERROR:
      return {
        ...state,
        transitionsDataCompleted: true,
        error: action.data as Error,
      };
    //CBO-2901_AI_AUTO_NEW_CODE : Added Redcuer for AppProgressionData, ServerProgressionData and ArchetypeList
    case EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_STARTED:
      return {
        ...state,
        appsProgressionResponse: [],
        appsProgressionCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_SUCCESS:
      return {
        ...state,
        appsProgressionResponse: action.data as IAppsProgressionResponse[],
        appsProgressionCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_ERROR:
      return {
        ...state,
        appsProgressionCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_STARTED:
      return {
        ...state,
        serversProgressionResponse: [],
        serversProgressionCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_SUCCESS:
      return {
        ...state,
        serversProgressionResponse: action.data as IServersProgressionResponse[],
        serversProgressionCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_ERROR:
      return {
        ...state,
        serversProgressionCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_STARTED:
      if ((action.data as IMigrationPayload)?.type === featureNames?.migrationProgress) {
        return {
          ...state,
          archetypesListData: {} as IMigrationArcheTypeListResponse,
          archetypesListCompleted: false,
        };
      } else if (
        (action.data as IMigrationPayload)?.type === featureNames?.blockersAndCausesForDelay
      ) {
        return {
          ...state,
          archetypesListData: {} as IMigrationArcheTypeListResponse,
          blockersandCausesListCompleted: false,
        };
      } else {
        return {
          ...state,
          archetypesListData: {} as IMigrationArcheTypeListResponse,
          mdotArchetypesListCompleted: false,
        };
      }

    case EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_SUCCESS:
      if ((action.data as IMigrationPayload)?.type === featureNames?.migrationProgress) {
        return {
          ...state,
          archetypesListData: action.data as IMigrationArcheTypeListResponse,
          archetypesListCompleted: true,
        };
      } else if (
        (action.data as IMigrationPayload)?.type === featureNames?.blockersAndCausesForDelay
      ) {
        return {
          ...state,
          archetypesListData: action.data as IMigrationArcheTypeListResponse,
          blockersandCausesListCompleted: true,
        };
      } else {
        return {
          ...state,
          archetypesListData: action.data as IMigrationArcheTypeListResponse,
          mdotArchetypesListCompleted: true,
        };
      }
    case EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_ERROR:
      if ((action.data as IMigrationPayload)?.type === featureNames?.migrationProgress) {
        return {
          ...state,
          archetypesListCompleted: true,
          error: action.data as Error,
        };
      } else if (
        (action.data as IMigrationPayload)?.type === featureNames?.blockersAndCausesForDelay
      ) {
        return {
          ...state,
          blockersandCausesListCompleted: true,
          error: action.data as Error,
        };
      } else {
        return {
          ...state,
          mdotArchetypesListCompleted: true,
          error: action.data as Error,
        };
      }
    //CBO-2901_AI_AUTO_NEW_CODE : Added new Reducer for AppProgressionMonthlyData, ServerProgressionMonthlyData
    case EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_STARTED:
      return {
        ...state,
        appsProgressionMonthlyResponse: [],
        appsProgressionMonthlyCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_SUCCESS:
      return {
        ...state,
        appsProgressionMonthlyResponse: action.data as IAppsProgressionMonthlyResponse[],
        appsProgressionMonthlyCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_ERROR:
      return {
        ...state,
        appsProgressionMonthlyCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_STARTED:
      return {
        ...state,
        serversProgressionMonthlyResponse: [],
        serversProgressionMonthlyCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_SUCCESS:
      return {
        ...state,
        serversProgressionMonthlyResponse: action.data as IServersProgressionMonthlyResponse[],
        serversProgressionMonthlyCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_ERROR:
      return {
        ...state,
        serversProgressionMonthlyCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_STARTED:
      return {
        ...state,
        tcoInsightsResponseData: {} as ITCOInsightsResponse,
        serversProgressionMonthlyCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_SUCCESS:
      return {
        ...state,
        tcoInsightsResponseData: action.data as ITCOInsightsResponse,
        tcoInsightsCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_ERROR:
      return {
        ...state,
        tcoInsightsCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_COST_TRENDS_STARTED:
      return {
        ...state,
        costTrendsResponseData: [] as ICostTrendResponse[],
        costTrendsCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_TRENDS_SUCCESS:
      return {
        ...state,
        costTrendsResponseData: action.data as ICostTrendResponse[],
        costTrendsCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_TRENDS_ERROR:
      return {
        ...state,
        costTrendsCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_STARTED:
      return {
        ...state,
        costAllocationSummaryResponseData: {} as ICostAllocationSummaryResponse,
        costAllocationSummaryCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_SUCCESS:
      return {
        ...state,
        costAllocationSummaryResponseData: action.data as ICostAllocationSummaryResponse,
        costAllocationSummaryCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_ERROR:
      return {
        ...state,
        costAllocationSummaryCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_STARTED:
      return {
        ...state,
        blockersandCausesResponseData: {} as IBlockersAndCausesResponse,
        blockersandCausesResponseCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_SUCCESS:
      return {
        ...state,
        blockersandCausesResponseData: action.data as IBlockersAndCausesResponse,
        blockersandCausesResponseCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_ERROR:
      return {
        ...state,
        blockersandCausesResponseCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_STARTED:
      return {
        ...state,
        AllocationTrendData: {} as IAllocationTrendResponse,
        AllocationTrendCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_SUCCESS:
      return {
        ...state,
        AllocationTrendData: action.data as IAllocationTrendResponse,
        AllocationTrendCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_ERROR:
      return {
        ...state,
        AllocationTrendCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_STARTED:
      return {
        ...state,
        cloudCostSummaryCostTrendData: [] as ICloudCostSummaryCostTrendResponse[],
        cloudCostSummaryCostTrendCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_SUCCESS:
      return {
        ...state,
        cloudCostSummaryCostTrendData: action.data as ICloudCostSummaryCostTrendResponse[],
        cloudCostSummaryCostTrendCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_ERROR:
      return {
        ...state,
        cloudCostSummaryCostTrendCompleted: true,
        error: action.data as Error,
      };
    // CBO-3061_AI_AUTO_ENHANCE_CODE:Created new Reducer for Servers By Upgrade
    case EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_STARTED:
      return {
        ...state,
        serversByUpgradeResponseData: [] as IServersByUpgradeResponse[],
        serversByUpgradeResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_SUCCESS:
      return {
        ...state,
        serversByUpgradeResponseData: action.data as IServersByUpgradeResponse[],
        serversByUpgradeResponseDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_ERROR:
      return {
        ...state,
        serversByUpgradeResponseDataCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_STARTED:
      return {
        ...state,
        dispositionsByWaveResponseData: {} as IDispositionsByWaveData,
        dispositionsByWaveDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_SUCCESS:
      return {
        ...state,
        dispositionsByWaveResponseData: action.data as IDispositionsByWaveData,
        dispositionsByWaveDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_ERROR:
      return {
        ...state,
        dispositionsByWaveDataCompleted: true,
        error: action.data as Error,
      };

    //CBO-4166_AI_AUTO_ENHANCE_CODE add case for following action types const keys = ['GET_DEFECT_AGEING_STARTED', 'GET_DEFECT_AGEING_SUCCESS', 'GET_DEFECT_AGEING_ERROR', 'GET_NUMBER_OF_TEST_CASES_STARTED', 'GET_NUMBER_OF_TEST_CASES_SUCCESS', 'GET_NUMBER_OF_TEST_CASES_ERROR', 'GET_SEVERITY_VS_CREATEDON_STARTED', 'GET_SEVERITY_VS_CREATEDON_SUCCESS', 'GET_SEVERITY_VS_CREATEDON_ERROR', 'GET_TABLE_COUNT_STARTED', 'GET_TABLE_COUNT_SUCCESS', 'GET_TABLE_COUNT_ERROR', 'GET_TEST_RUN_STATUS_STARTED', 'GET_TEST_RUN_STATUS_SUCCESS', 'GET_TEST_RUN_STATUS_ERROR'];
    case EngagementSummaryGraphActionTypes.GET_DEFECT_AGEING_STARTED:
      return {
        ...state,
        defectAgeingResponseData: {} as IDefectAgeingResponse,
        defectAgeingResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_DEFECT_AGEING_SUCCESS:
      return {
        ...state,
        defectAgeingResponseData: action.data as IDefectAgeingResponse,
        defectAgeingResponseDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_DEFECT_AGEING_ERROR:
      return {
        ...state,
        defectAgeingResponseDataCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_NUMBER_OF_TEST_CASES_STARTED:
      return {
        ...state,
        numberOfTestCasesResponseData: {} as INumberOfTestCasesResponse,
        numberOfTestCasesResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_NUMBER_OF_TEST_CASES_SUCCESS:
      return {
        ...state,
        numberOfTestCasesResponseData: action.data as INumberOfTestCasesResponse,
        numberOfTestCasesResponseDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_NUMBER_OF_TEST_CASES_ERROR:
      return {
        ...state,
        numberOfTestCasesResponseDataCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_SEVERITY_VS_CREATEDON_STARTED:
      return {
        ...state,
        severityVsCreatedOnResponseData: {} as ISeverityVsCreatedOnResponse,
        severityVsCreatedOnResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_SEVERITY_VS_CREATEDON_SUCCESS:
      return {
        ...state,
        severityVsCreatedOnResponseData: action.data as ISeverityVsCreatedOnResponse,
        severityVsCreatedOnResponseDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_SEVERITY_VS_CREATEDON_ERROR:
      return {
        ...state,
        severityVsCreatedOnResponseDataCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_TABLE_COUNT_STARTED:
      return {
        ...state,
        tableCountResponseData: {} as ITableCountResponse,
        tableCountResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_TABLE_COUNT_SUCCESS:
      return {
        ...state,
        tableCountResponseData: action.data as ITableCountResponse,
        tableCountResponseDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_TABLE_COUNT_ERROR:
      return {
        ...state,
        tableCountResponseDataCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_TEST_RUN_STATUS_STARTED:
      return {
        ...state,
        testRunStatusResponseData: {} as ITestRunStatusResponse,
        testRunStatusResponseDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_TEST_RUN_STATUS_SUCCESS:
      return {
        ...state,
        testRunStatusResponseData: action.data as ITestRunStatusResponse,
        testRunStatusResponseDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_TEST_RUN_STATUS_ERROR:
      return {
        ...state,
        testRunStatusResponseDataCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_TAXONOMY_EDITOR_DATA_STARTED:
      return {
        ...state,
        taxonomyEditorResponseData: [] as ITaxonomyEditorData[],
        taxonomyEditorDataCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_TAXONOMY_EDITOR_DATA_SUCCESS:
      return {
        ...state,
        taxonomyEditorResponseData: action.data as ITaxonomyEditorData[],
        taxonomyEditorDataCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_TAXONOMY_EDITOR_DATA_ERROR:
      return {
        ...state,
        taxonomyEditorDataCompleted: true,
        error: action.data as Error,
      };

    case EngagementSummaryGraphActionTypes.GET_SPEND_OPTIMIZATION_DATA_STARTED:
      return {
        ...state,
        spendOptimizationResponse: {} as ISpendOptimizationResponse,
        spendOptimizationCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_SPEND_OPTIMIZATION_DATA_SUCCESS:
      return {
        ...state,
        spendOptimizationResponse: action.data as ISpendOptimizationResponse,
        spendOptimizationCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_SPEND_OPTIMIZATION_DATA_ERROR:
      return {
        ...state,
        spendOptimizationCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_MULTI_CLOUD_CONSOLIDATION_STARTED:
      return {
        ...state,
        multicloudConsolidationData: [],
        multicloudConsolidationCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_MULTI_CLOUD_CONSOLIDATION_SUCCESS:
      return {
        ...state,
        multicloudConsolidationData: action.data as IMultiCloudConsolidationResponse[],
        multicloudConsolidationCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_MULTI_CLOUD_CONSOLIDATION_ERROR:
      return {
        ...state,
        multicloudConsolidationCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_QUARTER_COST_SUMMARY_STARTED:
      return {
        ...state,
        qaurterCostSummaryData: {} as IQuarterCostSummaryResponse,
        quarterCostSummaryCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_QUARTER_COST_SUMMARY_SUCCESS:
      return {
        ...state,
        qaurterCostSummaryData: action.data as IQuarterCostSummaryResponse,
        quarterCostSummaryCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_QUARTER_COST_SUMMARY_ERROR:
      return {
        ...state,
        quarterCostSummaryCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_BY_RESOURCE_STARTED:
      return {
        ...state,
        costByResourceData: [] as ICostByResourceResponse[],
        costByResourceCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_BY_RESOURCE_SUCCESS:
      return {
        ...state,
        costByResourceData: action.data as ICostByResourceResponse[],
        costByResourceCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_COST_BY_RESOURCE_ERROR:
      return {
        ...state,
        costByResourceCompleted: true,
        error: action.data as Error,
      };
    case EngagementSummaryGraphActionTypes.GET_YTD_COST_BY_RESOURCE_GROUP_STARTED:
      return {
        ...state,
        ytdCostByResourceGroupData: [] as IYTDCostByResourceResponse[],
        ytdCostByResourceGroupCompleted: false,
      };
    case EngagementSummaryGraphActionTypes.GET_YTD_COST_BY_RESOURCE_GROUP_SUCCESS:
      return {
        ...state,
        ytdCostByResourceGroupData: action.data as IYTDCostByResourceResponse[],
        ytdCostByResourceGroupCompleted: true,
      };
    case EngagementSummaryGraphActionTypes.GET_YTD_COST_BY_RESOURCE_GROUP_ERROR:
      return {
        ...state,
        ytdCostByResourceGroupCompleted: true,
        error: action.data as Error,
      };

    // reset all the state variable of summary graphs
    case EngagementSummaryGraphActionTypes.RESST_SUMMARY_GRAPH_DATA:
      return {
        ...state,
        //Reset trunOrth graph Data
        inventorySurvey: {} as ISurveyMetrics,
        businessSummary: {} as ISurveyMetrics,
        discoveryMetrics: {} as IDiscoveryMetrics,
        ApplicationDespositioning: {} as IApplicationDipositioning,
        wavePlan: {} as IWavePlan,
        //Reset innowake graph Data
        AllSqlData: [],
        DmlSqlData: [],
        OtherSqlData: [],
        DecmpositionData: [],
        MissingArtifactsData: [],
        AnalyzedArtifactsData: [],
        NumberofLOC: [],
        CodeQualityLow: [],
        CodeQualityLowForPCC: [],
        CodeQualityLowForLCC: [],
        CodeQualityLowForSC: [],
        CodeQualityMedium: [],
        CodeQualityMediumForPCC: [],
        CodeQualityMediumForLCC: [],
        CodeQualityHigh: [],
        CodeQualityHighForPCC: [],
        CodeQualityHighForLCC: [],
        CodeQualityVeryHigh: [],
        CodeQualityVeryHighForPCC: [],
        CodeQualityVeryHighForLCC: [],
        MetricsModule: [],
        PhysicalFiles: [],
        PhysicalLOC: [],
        SourceLOC: [],
        CommentLOC: [],
        DeadCode: [],
        MissingModules: [],
        Errors: [],
        //reset operateEdge graph Data
        incidentSummaryTrends: {} as IIncidentChartsType,
        incidentDetails: {} as IIncidentDetails,
        jiraIssuesInitiative: defaultJiraTableInitiative,
        jiraTableEpics: defaultJiraTableEpics,
        jiraTableTasks: defaultJiraTableTasks,
        jiraCurrentSprintTask: defaultCurrentSprintTask,
        jiraIssuesInitiativeModernize: defaultJiraTableInitiative, //Modernize CSA
        jiraTableTasksModernize: defaultJiraTableTasks, //modernize
        jiraModernizeSprintActivities: defaultCurrentSprintTask, //modernize
        //greencloud
        carbonFootPrintResponse: {} as ICarbonFootPrintResponse,
        carbonFootPrintCompleted: false,
        carbonCreditsResponse: {} as ICarbonCreditsResponse,
        carbonCreditsCompleted: false,
        energySavingsResponse: {} as IEnergySavingsResponse,
        energySavingsCompleted: false,
        availableDecompositionsCompleted: false,
        legacyGraphData: {} as ILegacyGraphData,
        legacyGraphCompleted: false,
        legacyDbCompleted: false,
        legacyDbData: {} as ILegacyDbData[],
        workflowsListData: [],
        workflowListDataCompleted: false,
        workflowExecutionMetricsDetails: {} as IExecutionMetricDetailsResponse,
        workflowExecutionMetricsDetailsCompleted: false,
        workflowReportsData: {} as IWorkflowReportResponse,
        summaryPractitionerDataResponse: {} as ISummaryPractitionerDataResp,
        coursesStatusDataResponse: [],
        workflowReportsDataCompleted: false,
        depAlertsScanCompleted: false,
        depAlertsScanData: {} as IDEPAlertScanResponse,
        depCodespacesData: [],
        depCodespacesCompleted: false,
        problemGroupOverview: {} as IProblemGroupOverviewResponse,
        problemGroupOverviewCompleted: false,
        problemInsightsRunDetails: {} as IProblemInsightsRunDetailsData,
        problemInsightsRunDetailsCompleted: false,
        problemInsightsRunListAssignmentGroup: [] as IProblemInsightsAssignmentGroupData[],
        problemInsightsRunListAssignmentGroupCompleted: false,
        kmsResponseData: [] as IKMSResponse[],
        kmsResponseDataCompleted: false,
        riskSummaryResponseData: [] as IRiskSummaryResponse[],
        issueSummaryResponseData: [] as IIssueSummaryResponse[],
        activeSummaryResponseData: {} as IActiveSummaryResponse,
        transitionsResponseData: [] as ITransitionTableResponseData[],
        riskSummaryDataCompleted: false,
        issueSummaryDataCompleted: false,
        activesummaryDataCompleted: false,
        transitionsDataCompleted: false,
        archetypesListData: {} as IMigrationArcheTypeListResponse,
        archetypesListCompleted: false,
        appsProgressionResponse: [],
        appsProgressionCompleted: false,
        serversProgressionCompleted: false,
        serversProgressionResponse: [],
        appsProgressionMonthlyCompleted: false,
        appsProgressionMonthlyResponse: [],
        serversProgressionMonthlyCompleted: false,
        serversProgressionMonthlyResponse: [],
        mdotArchetypesListCompleted: false,
        tcoInsightsResponseData: {} as ITCOInsightsResponse,
        tcoInsightsCompleted: false,
        costTrendsResponseData: [] as ICostTrendResponse[],
        costTrendsCompleted: false,
        costAllocationSummaryResponseData: {} as ICostAllocationSummaryResponse,
        costAllocationSummaryCompleted: false,
        blockersandCausesResponseData: {} as IBlockersAndCausesResponse,
        blockersandCausesResponseCompleted: true,
        blockersandCausesListCompleted: false,
        AllocationTrendData: {} as IAllocationTrendResponse,
        AllocationTrendCompleted: false,
        cloudCostSummaryCostTrendData: [] as ICloudCostSummaryCostTrendResponse[],
        cloudCostSummaryCostTrendCompleted: false,
        serversByUpgradeResponseData: [] as IServersByUpgradeResponse[],
        serversByUpgradeResponseDataCompleted: false,
        dispositionsByWaveResponseData: {} as IDispositionsByWaveData,
        dispositionsByWaveDataCompleted: true,
        defectAgeingResponseData: {} as IDefectAgeingResponse,
        defectAgeingResponseDataCompleted: false,
        severityVsCreatedOnResponseData: {} as ISeverityVsCreatedOnResponse,
        severityVsCreatedOnResponseDataCompleted: false,
        numberOfTestCasesResponseData: {} as INumberOfTestCasesResponse,
        numberOfTestCasesResponseDataCompleted: false,
        tableCountResponseData: {} as ITableCountResponse,
        tableCountResponseDataCompleted: false,
        testRunStatusResponseData: {} as ITestRunStatusResponse,
        testRunStatusResponseDataCompleted: false,
        taxonomyEditorResponseData: [],
        taxonomyEditorDataCompleted: false,
        spendOptimizationResponseData: {} as ISpendOptimizationResponse,
        spendOptimizationCompleted: false,
        multicloudConsolidationData: [],
        multicloudConsolidationCompleted: false,
        qaurterCostSummaryData: {} as IQuarterCostSummaryResponse,
        quarterCostSummaryCompleted: false,
        costByResourceData: [],
        costByResourceCompleted: false,
        ytdCostByResourceGroupData: [],
        ytdCostByResourceGroupCompleted: false,
      };
    default:
      return state;
  }
};
