/**
 * This file contains the definition of the routes used in the application.
 * It exports an object named "routes" which maps route names to their corresponding paths.
 * The routes object is used throughout the application to navigate between different pages.
 *
 * Example usage:
 * ```
 * import { routes } from './navigation/Routes';
 *
 * console.log(routes.dashboard); // Output: '/cboDashboard'
 * console.log(routes.assetAuthenticationRoutes.cms); // Output: 'cms'
 * ```
 */
export const routes = {
  error: {
    new: '/404',
  },
  login: { error: '/error' },
  dashboard: '/cboDashboard',
  authentication: '/authenticate',
  assetAuthenticationRoutes: {
    cwb: 'cwb',
    cms: 'cms',
    cb360: 'cb360',
    assess: 'assess',
    assessCallback: 'login-callback',
    dca: 'DCA',
    turbocode: 'turbocode',
    operateedge: 'operateedge',
    dep: 'dep',
  },

  cboFeedback: '/feedback',
  assessment: '/assessment',
  cbologin: '/cbo-login',
  cboDashboard: '/cboDashboard',
  cboEngagementDashboard: '/cboEngagementDashboard',
  cboJourneyBuilder: '/cboJourneyBuilder',
  cboCapabilityManagement: '/cboCapabilityManagement',
  CBOAssetAuthorization: '/cboAssetAuthorization',
  cboEngagementSummary: '/cboEngagementSummary',
  clientEngagementManagement: '/cboClientEngagementManagement',
  cboUserManagement: '/cboUserManagement',
  cboUserClientMapping: '/cboUserClientMapping',
  cboMarketplace: '/cboMarketplace',
  cboReports: '/cboReports',
  accessDenied: '/cboAccessDenied',
  cboMarketplaceSearch: '/cboMarketplaceSearch',
  cboTruNorthDashboard: '/cboTruNorthDashboard',
  cboIframeTest: '/cboIframeTest',
  cboSmartStart: '/cboSmartStart',
};
