import { IEngagementResponse } from '../../../models/cbo/ClientEngagementManagementModel';
import { IEngagementListForAdminRequestPayload } from '../../../models/cbo/EngagementModels';
import {
  ICreateUserRequestBody,
  ICboUserManagementObject,
  IClientListMapping,
  IEditUserRequestBody,
  IShowUserClientPayload,
  IUser,
} from '../../../models/cbo/UserManagementModel';

import { IStoreAction } from '../../StoreHelper';

export enum UserManagementActionTypes {
  GET_USER_LIST_STARTED = 'GET_USER_LIST_STARTED',
  GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR',
  GET_CLIENT_ENG_LIST_STARTED = 'GET_CLIENT_ENG_LIST_STARTED',
  GET_CLIENT_ENG_LIST_SUCCESS = 'GET_CLIENT_ENG_LIST_SUCCESS',
  GET_CLIENT_ENG_LIST_ERROR = 'GET_CLIENT_ENG_LIST_ERROR',
  ADD_USER_DATA_STARTED = 'ADD_USER_DATA_STARTED',
  ADD_USER_DATA_SUCCESS = 'ADD_USER_DATA_SUCCESS',
  ADD_USER_DATA_ERROR = 'ADD_USER_DATA_ERROR',
  EDIT_USER_STATUS_STARTED = 'EDIT_USER_STATUS_STARTED',
  EDIT_USER_STATUS_SUCCESS = 'EDIT_USER_STATUS_SUCCESS',
  EDIT_USER_STATUS_ERROR = 'EDIT_USER_STATUS_ERROR',
  UPDATE_USER_DATA_STARTED = 'UPDATE_USER_DATA_STARTED',
  UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR',
  GET_ROLE_METADATA_STARTED = 'GET_ROLE_METADATA_STARTED',
  GET_ROLE_METADATA_SUCCESS = 'GET_ROLE_METADATA_SUCCESS',
  GET_ROLE_METADATA_ERROR = 'GET_ROLE_METADATA_ERROR',
  GET_CLIENT_USER_STARTED = 'GET_CLIENT_USER_STARTED',
  GET_CLIENT_USER_SUCCESS = 'GET_CLIENT_USER_SUCCESS',
  GET_CLIENT_USER_ERROR = 'GET_CLIENT_USER_ERROR',

  SHOW_USER_CLIENT_STARTED = 'SHOW_USER_CLIENT_STARTED',
  SHOW_USER_CLIENT_SUCCESS = 'SHOW_USER_CLIENT_SUCCESS',
  SHOW_USER_CLIENT_ERROR = 'SHOW_USER_CLIENT_ERROR',
  //CBO-5067_AI_AUTO_NEW_CODE:generated action types for get user details start, success and error
  GET_USER_DETAILS_STARTED = 'GET_USER_DETAILS_STARTED',
  GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR',
}

export type UserManagementActionPayload =
  | Error
  | null
  | string
  | string[]
  | ICboUserManagementObject
  | ICboUserManagementObject[]
  | ICreateUserRequestBody
  | IClientListMapping[]
  | IEditUserRequestBody
  | IEngagementResponse[]
  | IEngagementResponse
  | IEngagementListForAdminRequestPayload
  | IShowUserClientPayload
  | IUser;

export type UserManagementAction = IStoreAction<
  UserManagementActionTypes,
  UserManagementActionPayload
>;

export class UserManagementActions {
  public static getUserListStart(
    data: IEngagementListForAdminRequestPayload,
  ): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_LIST_STARTED,
      data,
    };
  }
  public static getUserListSuccess(data: ICboUserManagementObject): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_LIST_SUCCESS,
      data,
    };
  }
  public static getUserListError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_LIST_ERROR,
      data: error,
    };
  }
  public static getClientEngListStart(): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_CLIENT_ENG_LIST_STARTED,
    };
  }
  public static getClientEngListSuccess(data: IClientListMapping[]): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_CLIENT_ENG_LIST_SUCCESS,
      data,
    };
  }
  public static getClientEngListError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_CLIENT_ENG_LIST_ERROR,
      data: error,
    };
  }

  public static addUserStart(data: ICreateUserRequestBody): UserManagementAction {
    return {
      type: UserManagementActionTypes.ADD_USER_DATA_STARTED,
      data,
    };
  }
  public static addUserSuccess(): UserManagementAction {
    return {
      type: UserManagementActionTypes.ADD_USER_DATA_SUCCESS,
    };
  }
  public static addUserError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.ADD_USER_DATA_ERROR,
      data: error,
    };
  }
  public static editUserStatusStart(data: ICboUserManagementObject): UserManagementAction {
    return {
      type: UserManagementActionTypes.EDIT_USER_STATUS_STARTED,
      data,
    };
  }
  public static editUserStatusSuccess(): UserManagementAction {
    return {
      type: UserManagementActionTypes.EDIT_USER_STATUS_SUCCESS,
    };
  }
  public static editUserStatusError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.EDIT_USER_STATUS_ERROR,
      data: error,
    };
  }
  public static editUserStart(data: IEditUserRequestBody): UserManagementAction {
    return {
      type: UserManagementActionTypes.UPDATE_USER_DATA_STARTED,
      data,
    };
  }
  public static editUserSuccess(): UserManagementAction {
    return {
      type: UserManagementActionTypes.UPDATE_USER_DATA_SUCCESS,
    };
  }
  public static editUserError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.UPDATE_USER_DATA_ERROR,
      data: error,
    };
  }
  public static getRoleMetaDataStart(): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_ROLE_METADATA_STARTED,
    };
  }
  public static getRoleMetaDataSuccess(data: string[]): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_ROLE_METADATA_SUCCESS,
      data,
    };
  }
  public static getRoleMetaDataError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_ROLE_METADATA_ERROR,
      data: error,
    };
  }

  public static getClientUserStart(data: string): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_CLIENT_USER_STARTED,
      data,
    };
  }
  public static getClientUserSuccess(data: IEngagementResponse[]): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_CLIENT_USER_SUCCESS,
      data,
    };
  }
  public static getClientUserError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_CLIENT_USER_ERROR,
      data: error,
    };
  }
  // @CBO-2834_AI_AUTO_NEW_CODE:generated saction for show user's client's start, success and error
  public static showUserClientStart(data: IShowUserClientPayload): UserManagementAction {
    return {
      type: UserManagementActionTypes.SHOW_USER_CLIENT_STARTED,
      data,
    };
  }
  public static showUserClientSuccess(): UserManagementAction {
    return {
      type: UserManagementActionTypes.SHOW_USER_CLIENT_SUCCESS,
    };
  }
  public static showUserClientError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.SHOW_USER_CLIENT_ERROR,
      data: error,
    };
  }
  // CBO-5067_AI_AUTO_NEW_CODE:generated action for get user details start, success and error
  public static getUserDetailsStart(data: string): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_DETAILS_STARTED,
      data,
    };
  }
  public static getUserDetailsSuccess(data: IUser): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_DETAILS_SUCCESS,
      data,
    };
  }
  public static getUserDetailsError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_DETAILS_ERROR,
      data: error,
    };
  }
}
