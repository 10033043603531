import { RBACMapping } from '../../components/Auth/AuthenticationPageConstants';
import { routes } from '../../navigation/Routes';
import { smartPMOFeaturesList } from '../CBOEngagementSummary/CBOEngagementSummaryConstants';

export const CBOKeyAssets = {
  defaultSelectedKey: 'cboDashboard',
  cboDCAAsset: 'Deloitte Cloud Accelerator',
  cboTruNorthModernizationCentral: 'TruNorth Modernization Central',
  cboCWBAsset: 'Cloud Workbook',
  cboCB360Asset: 'Cloud Billing 360',
  cboApiVault: 'API Vault',
  cboTruNorthDecomposeAsset: 'TruNorth Decompose',
  cboTalkQEAsset: 'Digital Tester', //currently talkQE is pointed to digital tester
  cboDigitalTester: 'Digital Tester',
  cboCOFTAAsset: 'Cognitive Functional  Test Automation  (COFTA)',
  cms: 'OpenCloud',
  operateEdge: 'OperateEdge',
  truNorthCodeDiscovery: 'TruNorth Code Discovery',
  cboDCPAsset: 'DCP',
  cboATAVision: 'TruNorth + ATAVision', //ATAVision
  cboInnoWakeTransformationAssset: 'innoWake Transformation',
  cboProcessBionics: 'Process Bionics',
  cboATAMotion: 'ATAMotion', // ATAMotion and
  cboATATransform: 'ATATransform', //ATATransform
  cboDEPAsset: 'Deloitte Engineering Platform',
  aiAssist: 'AI Assist',
  cboQaAssist: 'QA Assist',
  cboUIAssist: 'UI Assist',
  cboDevAssist: 'Dev Assist',
  cboPOAssist: 'PO Assist',
  cboArchitectAssist: 'Architect Assist',
  cboDMD: 'Digital Maturity Diagnostic (DMD)',
  cboDigitalPMO: 'DigitalPMO',
  cboChangeScout: 'Change Scout',
  cboADA: 'Analytics Digital Accelerator',
  cboIntergrationAssist: 'Integration Assist',
  cboPMAssist: 'Project Manager Assist',
  cboDataEngineerAssist: 'Data Engineering Assist',
  cbo5GIgnite: '5G Ignite',
  unstructuredDataExtractor: 'Unstructured data extractor',
  syntheticDataGenerator: 'Synthetic data generator',
  etlCodeConverter: 'ETL code converter',
  sttmMappingGenerator: 'STTM mapping generator',
  etlCodeTester: 'ETL code tester (CATE)',
  dataMasking: 'Data masking',
  intelligentBusinessGlossary: 'Intelligent business glossary',
  dataPipelineGenerator: 'Data pipeline generator',
  mdmAsAService: 'MDM as a Service',
  etlCodeGenerator: 'ETL code generator',
  integrationWithDataCatalogPlatforms: 'Integration with data catalog platforms',
  codeDocumentation: 'Code documentation',
  reportConverter: 'Report converter',
  sasCodeConverter: 'SAS code converter',
  smartMap: 'Source to target mapping, workflow and lineage (Smart Map)',
  historyDataLoad: 'History data load',
  dataProfiler: 'Data profiler',
  smartDQ: 'Data quality rules generator (Smart DQ)',
  dbCodeConverter: 'DB code converter',
  etlCodeReverseEngineering: 'ETL code reverse engineering',
  ascendForOperate: 'Ascend for Operate',
  schedulerConverter: 'Scheduler converter',
  reportReverseEngineering: 'Report reverse engineering (DRECT)',
  automatedReportsTester: 'Automated reports tester',
  llmOps: 'LLMOps',
  mlOps: 'MLOps',
  dao: 'Deloitte analytics objects (DAO)',
};

export const CBOKeyAssetsDisplayName = {
  defaultSelectedKey: 'cboDashboard',
  cboDCAAsset: 'Cloud Foundations Build',
  cboTruNorthModernizationCentral: 'Cloud Dispositions',
  cboCWBAsset: 'Cloud Business Case',
  cboCB360Asset: 'Cloud Financial Analysis',
  cboApiVault: 'API Vault',
  cboTruNorthDecomposeModernization: 'Data Modernization',
  cboTruNorthDecomposeDecomposition: 'Monolith Decomposition',
  cboTalkQEAsset: 'TalkQE',
  cboDigitalTester: 'Digital Tester',
  cboCOFTAAsset: 'COFTA',
  cms: 'Cloud Managed Services',
  operateEdge: 'Application Managed Services',
  truNorthCodeDiscovery: 'Mainframe Discovery',
  cboDCPAsset: 'DevOps',
  cboTruNorthInfrastructureDiscovery: 'ATAVision',
  cboInnoWakeTransformationAssset: 'innoWake Transformation',
  cboDEPAsset: 'Deloitte Engineering Platform',
};

export const TruNorthFeature = {
  discoveryMetrics: 'Discovery Metrics',
  applicationDispositioning: 'Application Dispositioning',
  surveyCompletionMetrics: 'Survey Completion Metrics',
  migrationStrategies: 'Migration Strategies',
};

export interface ISubMenuItem {
  title: string;
  key: string;
}

export interface IMenuItem {
  title: string;
  key: string;
  icon: string;
  children?: ISubMenuItem[];
  check: string;
}

export const TopMenuItemsArray: IMenuItem[] = [
  {
    title: 'Pursuit Dashboard',
    key: routes.cboDashboard,
    icon: 'sidebar-pursuit-dashboard.svg',
    check: '',
  },
  {
    title: 'Engagement Dashboard',
    key: routes.cboEngagementDashboard,
    icon: 'sidebar_dashboard_white.svg',
    check: '',
  },

  {
    title: 'Assess',
    key: 'assesAssets',
    icon: 'Assess.svg',
    check: '',
  },
  {
    title: 'Modernize',
    key: 'modernizeAssets',
    icon: 'Modernize.svg',
    check: '',
  },
  {
    title: 'Test',
    key: 'testAssets',
    icon: 'qe-icon.svg',
    check: '',
  },
  {
    title: 'Operate',
    key: 'operateAssets',
    icon: 'operate-sideNav.svg',
    check: '',
  },
  {
    title: 'Marketplace',
    key: routes.cboMarketplaceSearch,
    icon: 'marketplace-white-icon.svg',
    check: RBACMapping.engagementMarketplace,
  },
];

export const BottomMenuItemsArray: IMenuItem[] = [
  {
    title: 'Settings',
    key: 'settings',
    icon: 'sidebar_settings_black.svg',
    check: '',
  },
  {
    title: 'Reports',
    key: routes.cboReports,
    icon: 'Reports.svg',
    check: RBACMapping.reports,
  },
  {
    title: 'Support',
    key: routes.cboFeedback,
    icon: 'feedback-white.svg',
    check: '',
  },
  {
    title: 'Product Tour',
    key: 'help',
    icon: 'light_theme_sidebar_help_icon_black.svg',
    check: RBACMapping.productTour,
  },
];

export const SettingsSubMenu = {
  clientEngagements: 'Client & Pursuits',
  userManagement: 'User Management',
  userClientMapping: 'User Client Mapping',
  masterData: 'Master Data',
  journeyBuilder: 'Journey Builder',
  smartStart: 'SmartStart Management',
};

export const AssetMenuItems = {
  modernizeAssets: [
    {
      label: CBOKeyAssets.cboTruNorthDecomposeAsset,
      displayName: CBOKeyAssetsDisplayName.cboTruNorthDecomposeDecomposition,
    },
    {
      label: CBOKeyAssets.cboTruNorthDecomposeAsset,
      displayName: CBOKeyAssetsDisplayName.cboTruNorthDecomposeModernization,
    },
    {
      label: CBOKeyAssets.cboDCAAsset,
      displayName: CBOKeyAssetsDisplayName.cboDCAAsset,
    },
    {
      label: CBOKeyAssets.cboDCPAsset,
      displayName: CBOKeyAssetsDisplayName.cboDCPAsset,
    },
    {
      label: CBOKeyAssets.cboApiVault,
      displayName: CBOKeyAssetsDisplayName.cboApiVault,
    },
  ],
  operateAssets: [
    {
      label: CBOKeyAssets.operateEdge,
      displayName: CBOKeyAssetsDisplayName.operateEdge,
    },
    {
      label: CBOKeyAssets.cms,
      displayName: CBOKeyAssetsDisplayName.cms,
    },
  ],
  assessAssets: [
    {
      label: CBOKeyAssets.cboCWBAsset,
      displayName: CBOKeyAssetsDisplayName.cboCWBAsset,
    },
    {
      label: CBOKeyAssets.cboTruNorthModernizationCentral,
      displayName: CBOKeyAssetsDisplayName.cboTruNorthModernizationCentral,
    },
    {
      label: CBOKeyAssets.truNorthCodeDiscovery,
      displayName: CBOKeyAssetsDisplayName.truNorthCodeDiscovery,
    },
    {
      label: CBOKeyAssets.cboCB360Asset,
      displayName: CBOKeyAssetsDisplayName.cboCB360Asset,
    },
  ],
  testAssets: [
    {
      label: CBOKeyAssets.cboDigitalTester,
      displayName: CBOKeyAssetsDisplayName.cboDigitalTester,
    },
  ],
};

export const SettingsMenuItems = [
  {
    rbacMapping: RBACMapping.clientEngagementView,
    route: routes.clientEngagementManagement,
    label: SettingsSubMenu.clientEngagements,
  },
  {
    rbacMapping: RBACMapping.viewUser,
    route: routes.cboUserManagement,
    label: SettingsSubMenu.userManagement,
  },
  {
    rbacMapping: RBACMapping.viewUserClientMapping,
    route: routes.cboUserClientMapping,
    label: SettingsSubMenu.userClientMapping,
  },
  {
    rbacMapping: RBACMapping.capabilityList,
    route: routes.cboCapabilityManagement,
    label: SettingsSubMenu.masterData,
  },
  {
    rbacMapping: RBACMapping.journeyList,
    route: routes.cboJourneyBuilder,
    label: SettingsSubMenu.journeyBuilder,
  },
  {
    rbacMapping: RBACMapping.smartStart,
    route: routes.cboSmartStart,
    label: SettingsSubMenu.smartStart,
  },
];

export const MenuItems = {
  webanalytcs: { key: 'reports', title: 'Reports' },
  modernizeAssets: { key: 'modernizeAssets', title: 'Modernize' },
  operateAssets: { key: 'operateAssets', title: 'Operate' },
  assessAssets: { key: 'assessAssets', title: 'Assess' },
  testAssets: { key: 'testAssets', title: 'Test' },
  settings: { key: 'settings', title: 'Settings' },
  feedback: { key: 'feedback', title: 'Feedback' },
  help: { key: 'help', title: 'Product Tour' },
};

export const assetToolTip = 'Please select a Pursuit to work in this Module.';
