import { Modal } from 'antd';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router';
// import { Route, Navigate } from 'react-router';
import { isAuthorized } from '../../libs/helper/HelperFunctions';
import { routes } from '../../navigation/Routes';
import UnauthorizedModal from '../Auth/UnauthorizedModal/UnauthorizedModal';
import { useCustomAppSelector } from '../Auth/useCustomeAppSelector';

interface IProtectedRouteProps {
  children: React.ReactNode;
  action: string;
}

const ProtectedRoute = ({ children, action }: IProtectedRouteProps) => {
  const rbacData = useCustomAppSelector();
  return (
    <>
      {rbacData?.length > 0 ? (
        isAuthorized(action, rbacData) ? (
          children
        ) : (
          <Navigate to={routes.accessDenied} />
        )
      ) : null}
    </>
  );
};

export default ProtectedRoute;
